<template>
  <button v-if="locales.length" class="app-lang-switcher" :class="{ '--open': dropdownVisible }">
    <div v-if="currentLocale" class="trigger">
      <img
        v-lazy-load :data-src="currentLocale.flag"
        class="flag"
        :alt="`${currentLocale.name} flag`"
        width="16"
        height="10"
      />{{ currentLocale.name }}
      <ArrowIcon class="arrow" />
    </div>
    <ul class="list">
      <li
        v-for="locale in filteredLocales"
        :key="locale.name"
        class="list-item"
        @click="changeLang(locale.name)"
      >
        <img
          v-lazy-load :data-src="locale.flag"
          class="flag"
          :alt="`${locale.name} flag`"
          width="16"
          height="10"
        />{{ locale.name }}
      </li>
    </ul>
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ArrowIcon from '@/components/icons/ArrowIcon'

export default {
  name: 'AppLangSwitcher',
  components: { ArrowIcon },

  data() {
    return {
      locales: [],
      dropdownVisible: false,
    }
  },
  computed: {
    filteredLocales() {
      return this.locales.filter((item) => item.name !== this.getLangToChange)
    },
    ...mapGetters(['getLangToChange']),
    currentLocale() {
      // if(this.getCurrentLanguage === 'en') return this.locals
      return this.locales.find((item) => item.name === this.getLangToChange)
    },
    enabledLocales() {
      return this.$store.state.enabledLocales
    },
  },
  watch: {
    currentLocale(v) {
      this.$emit('locale', v)
    },
  },
  mounted() {
    const locales = []

    if (this.enabledLocales.includes('en')) {
      locales.push({
        name: 'en',
        flag: '/flags/en.svg',
      })
    }

    if (this.enabledLocales.includes('fr')) {
      locales.push({
        name: 'fr',
        flag: '/flags/fr.svg',
      })
    }

    if (this.enabledLocales.includes('de')) {
      locales.push({
        name: 'de',
        flag: '/flags/de.svg',
      })
    }

    if (this.enabledLocales.includes('es')) {
      locales.push({
        name: 'es',
        flag: '/flags/es.svg',
      })
    }

    if (this.enabledLocales.includes('pl')) {
      locales.push({
        name: 'pl',
        flag: '/flags/pl.svg',
      })
    }

    this.locales = locales
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLang(lang) {
      const langObj = {
        lang,
        name: `${this.$route.meta.slug}__${lang}`,
      }
      this.setLanguage(langObj)
      this.hideDropdown()
    },
    showDropdown() {
      this.dropdownVisible = true
    },
    hideDropdown() {
      this.dropdownVisible = false
    },
    bindDropdown() {
      this.dropdownVisible = !this.dropdownVisible
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/AppLangSwitcher';
</style>
