<template>
  <div>
    <div class="app-navigation --sticky-navigation">
      <div v-if="getNavigationData" class="nav-container">
        <AppLangSwitcher class="lang-switcher --desktop" />
        <nav class="nav">
          <nuxt-link
            class="homepage-link"
            :to="{ name: `${HOMEPAGE.slug}__${getCurrentLanguage}` }"
            name="homepage link"
            title="homepage link"
            no-prefetch
            @click.native="navOpen = false"
          >
            <ResimoLogotype />
          </nuxt-link>
          <div class="menu-container" :class="{ '--open': navOpen }">
            <ul class="menu">
              <li v-for="item in NAV_ROUTES" :key="item.title" class="menu-item">
                <nuxt-link
                  class="menu-link"
                  :to="{ name: `${item.slug}__${getCurrentLanguage}` }"
                  :title="getNavigationData[item.meta.key][getCurrentLanguage]"
                  no-prefetch
                  @click.native="navOpen = false"
                >
                  {{ getNavigationData[item.meta.key][getCurrentLanguage] }}
                </nuxt-link>
              </li>
            </ul>

            <AppButton disable-aos class="--mobile" size="small" @click.native.stop="requestDemo">
              {{ getGeneralContent.requestADemo }} <ArrowLinearIcon
            /></AppButton>

            <AppLangSwitcher class="lang-switcher --mobile" @locale="navOpen = false" />
          </div>
          <AppButton
            class="--desktop"
            size="small"
            disable-aos
            @click.native.stop="setCalendlyPopupVisibility(true)"
          >
            {{ getGeneralContent.requestADemo }} <ArrowLinearIcon
          /></AppButton>
        </nav>
      </div>
      <button
        class="mobile-trigger-container"
        name="mobile menu trigger"
        aria-label="Mobile menu trigger"
        @click.self="navOpen = !navOpen"
      >
        <div class="mobile-menu-button" :class="{ '--open': navOpen }">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </div>

    <div
      ref="navRef"
      class="app-navigation --fixed-navigation"
      :class="{ '--open': navOpen, '--not-at-top': notAtTop, '--hide': hideOnScroll }"
    >
      <div v-if="getNavigationData" class="nav-container">
        <nav class="nav">
          <nuxt-link
            class="homepage-link"
            :to="{ name: `${HOMEPAGE.slug}__${getCurrentLanguage}` }"
            name="homepage link"
            title="homepage link"
            no-prefetch
            @click.native="navOpen = false"
          >
            <ResimoLogotype />
          </nuxt-link>
          <div class="menu-container" :class="{ '--open': navOpen }">
            <ul class="menu">
              <li v-for="item in NAV_ROUTES" :key="item.title" class="menu-item">
                <nuxt-link
                  class="menu-link"
                  :to="{ name: `${item.slug}__${getCurrentLanguage}` }"
                  :title="getNavigationData[item.meta.key][getCurrentLanguage]"
                  no-prefetch
                  @click.native="navOpen = false"
                >
                  {{ getNavigationData[item.meta.key][getCurrentLanguage] }}
                </nuxt-link>
              </li>
            </ul>

            <AppButton disable-aos class="--mobile" size="small" @click.native.stop="requestDemo">
              {{ getGeneralContent.requestADemo }} <ArrowLinearIcon
            /></AppButton>

            <AppLangSwitcher class="lang-switcher --mobile" @locale="navOpen = false" />
          </div>
          <AppButton
            class="--desktop"
            size="small"
            disable-aos
            @click.native.stop="setCalendlyPopupVisibility(true)"
          >
            {{ getGeneralContent.requestADemo }} <ArrowLinearIcon
          /></AppButton>
        </nav>
      </div>
      <button
        class="mobile-trigger-container"
        name="mobile menu trigger"
        aria-label="Mobile menu trigger"
        @click.self="navOpen = !navOpen"
      >
        <div class="mobile-menu-button" :class="{ '--open': navOpen }">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isMobile } from '@/utils/mobile-device-detect.js'
import ResimoLogotype from '@/components/icons/ResimoLogotype'
import ArrowLinearIcon from '@/components/icons/ArrowLinearIcon'
import AppButton from '@/components/common/AppButton'
import AppLangSwitcher from '@/components/common/AppLangSwitcher'

import { ROUTES } from '@/utils/routes'

export default {
  name: 'AppNavigation',
  components: {
    ResimoLogotype,
    ArrowLinearIcon,
    AppButton,
    AppLangSwitcher,
  },
  data() {
    return {
      NAV_ROUTES: [],
      navOpen: false,
      notAtTop: false,
      hideOnScroll: false,
      absolute: true,
      HOMEPAGE: ROUTES.HOMEPAGE,
      isMobile,
    }
  },
  computed: {
    ...mapGetters(['getNavigationData', 'getCurrentLanguage', 'getGeneralContent']),
  },
  watch: {
    navOpen(v) {
      if (v) {
        document?.body?.classList.add('--overflow-hidden')
      } else {
        document?.body.classList.remove('--overflow-hidden')
      }
    },
  },
  beforeMount() {
    const { SALES_APP_3D, WEB_SALES_SOLUTION, CASE_STUDIES, ABOUT_US, CONTACT } = ROUTES
    this.NAV_ROUTES = [SALES_APP_3D, WEB_SALES_SOLUTION, CASE_STUDIES, ABOUT_US, CONTACT]
  },
  mounted() {
    if (this.$refs.navRef) {
      const HEADER_SIZE = 20
      const HEADER_SECTION_SIZE = this.isMobile ? 64 : 0.8 * window.innerHeight
      const getScrollPos = () => window.scrollY || document.documentElement.scrollTop
      const position = {
        current: getScrollPos(),
        previous: getScrollPos(),
      }

      const onScrollHandler = () => {
        position.current = getScrollPos()

        if (
          position.current > HEADER_SIZE &&
          position.current >= position.previous &&
          position.current > HEADER_SECTION_SIZE
        ) {
          this.notAtTop = true
        } else if (position.current <= HEADER_SIZE || position.current <= HEADER_SECTION_SIZE) {
          this.notAtTop = false
        }

        if (position.current > HEADER_SECTION_SIZE) {
          this.absolute = false
        } else {
          this.absolute = true
        }

        if (position.current > position.previous && isMobile && position.current > 72) {
          this.hideOnScroll = true
          this.navOpen = false
        } else {
          this.hideOnScroll = false
        }

        position.previous = position.current
      }

      onScrollHandler()
      document.addEventListener('scroll', onScrollHandler)
    }
  },
  methods: {
    ...mapActions(['setCalendlyPopupVisibility', 'getGlobalContactData']),
    requestDemo() {
      this.setCalendlyPopupVisibility(true)
      this.navOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/AppNavigation';
</style>
